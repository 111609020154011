@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor:#247E81;
$secondaryColor: #D46119;

body{
    font-size: 18px;
	background-color: #f1f1f1;
}

.item{
    margin: 10px;
    text-align: center;
	background-color: #fff;
	border:1px solid rgb(170, 170, 170);
	font-size: large;
	padding: 20px;
}

.item h1{
	font-weight: bold;
}

.item img{
    border-radius: 10px;
}

.page-header {
	background-image: url("../../images/pamovations-stack.png");
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #090909;
	background-attachment: fixed;
	color: #fff;
	min-height: 760px;
}

.menu{
    background-color: $primaryColor;
}

.menu-scrolled{
    background-color: $primaryColor;
    position: fixed;
}

.menu ul li{
    color:#fff;
	font-weight: bold;
}

.box-header h1{
	color:#247E81;
}

.item h1{
	color:#247E81;
}
